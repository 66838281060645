<template lang="pug">
.landing
    section.cover
        h1.animated.fadeIn You need a schema registry.

        p.animated.fadeIn Are you building an event-driven platform? Do your services exchange messages? <b>Optimize</b> your payloads and discover how <b>easy</b> schema governance can be!
        
        p.animated.fadeIn No infrastructure to manage, SaaS-easy.

        .actions
            router-link.back(:to="{ name: 'signup' }")
                button.animated.fadeIn Sign up for beta

    .wrapper
        section#tutorial
            h2.animated.fadeIn How SchemaDB works
            .step
                .description.inline-block
                    h4 1. Save a new schema version
                    p Import SchemaDB SDK and initialize it with your API Token. Read our <a href="https://schemadb.github.io/node-sdk" target="_blank">developer docs</a> for detailed instructions.

                    p Declare your Avro schema following <a href="https://avro.apache.org/docs/current/spec.html#json_encoding" target="_blank">the specification</a>.

                    p Use the SDK to save the version to SchemaDB. After that, your schema will appear on your <a href="/dashboard" target="_blank">Dashboard</a> 
                        | and will be available to serialize and parse your messages.
                img.inline-block(:src="startImg")

            .step
                .description.inline-block
                    h4 2. Encode your payload
                    p Fetch the lastest schema version by its <b>namespace</b> and <b>name</b> using the SDK. You can also get an speficic version if you prefer, 
                         | Read our <a href="https://schemadb.github.io/node-sdk" target="_blank">developer docs</a> for more details.
                    p Once you have the schema, simply use the SDK to serialize your message. The SDK handles validation and caching for you.

                    p After that you cand send your message out to a Kafka cluster or any other streaming platform.
                img.inline-block(:src="encodeImg")

            .step
                .description.inline-block
                    h4 3. Decode Avro messages
                    p When you encode a message using our SDK we include the schema reference, so we can decode it anytime.

                    p This <b>decode</b> method gets the schema used for serialization for you, and uses it for parsing. 
                        | After that, you get the original payload as a JSON object.

                img.inline-block(:src="decodeImg")

    section#dashboard
        h2 User-friendly Dashboard

        img.dashboard(:src="uiImg")

    .wrapper
        section#features
            .feature.inline-block
                i.icon.material-icons-outlined.inline-block done_all
                .description.inline-block
                    label Easy
                    p Set it all up in a few minutes. Use our SDK al let it to all the heavy-lifting for you.
            .feature.inline-block
                i.icon.material-icons-outlined.inline-block speed
                .description.inline-block
                    label Fast
                    p Our SDK bundles a blazing fast serializer and deserializar. It also handles caching for you.
            .feature.inline-block
                i.icon.material-icons-outlined.inline-block trending_up
                .description.inline-block
                    label scalable
                    p It doesn't matter if you have 2 or 2 million schemas, SchemaDB grows at the pace you need.

            .actions
                router-link.back(:to="{ name: 'signup' }")
                    button Sign up for beta

    footer
        label SchemaDB  👋  
        a(href="mailto:hi@schemadb.com") hi@schemadb.com
</template>

<script>
export default {
    computed: {
        encodeImg: () => require('@/assets/img/encoding.png'),
        decodeImg: () => require('@/assets/img/decoding.png'),
        startImg: () => require('@/assets/img/register_schema.png'),
        uiImg: () => require('@/assets/img/dashboard.png'),
    }
};
</script>
<style scoped>
    .landing {
        position: relative;
        display: block;
        width: 100%;
    }
    section {
        padding: 10vh 0;
    }

    .wrapper {
        position: relative;
        display: block;
        background: #f9fbfc;
        width: 100vw;
        left: calc(var(--padding-large) * -1);
    }

    h1 {
        font-size: 96px;
        text-decoration: underline;
        margin-top: 5vh;
        margin-bottom: 60px;
        letter-spacing: 2px;
    }

    .cover p, .actions {
        width: 75%;
    }

    .cover p, .cover  b {
        font-size: 20px;
        line-height: 30px;
    }
    .cover p {
        color: rgba(64,64,64,.9);
        text-align: justify;
        margin-bottom: var(--margin-base);
    }
   .cover p:last-of-type {
        margin-bottom: calc(var(--margin-base) * 2);
    }

    .actions {
        text-align: right;
    }

    button {
        position: relative;
        display: block;
        outline: none;
        cursor: pointer;
        color: #fff;
        background: var(--text-color);
        border: none;
        width: 200px;
        padding: var(--padding-base) var(--padding-base);
        text-transform: uppercase;
        font-weight: bold;
        font-size: var(--font-size-small);
        border-radius: 100px;
        box-shadow: 0px 4px 8px rgba(64,64,64,.2);

        -webkit-transition: all ease-in-out .2s;
        -moz-transition: all ease-in-out .2s;
        -o-transition: all ease-in-out .2s;
        transition: all ease-in-out .2s;
    }

    button:active {
        box-shadow: none;
        transform: scale(.96);
    }
    section h2 {
        font-size: 64px;
        margin-bottom: var(--margin-large);
    }
    section#tutorial .step {
        margin-bottom: 2vh;
    }
    section#tutorial .step > * {
        vertical-align: middle; 
        width: 50%;
    }

    section#tutorial .step .description h4 {
        font-size: var(--font-size-large);
        margin-bottom: var(--margin-large);
    }
    section#tutorial .step .description p {
        font-size: var(--font-size-base);
        color: rgba(64,64,64,.7);
        line-height: 28px;
        text-align: justify;
    }

    section#tutorial .step img {
        right: -4%;
    }

    section#features .feature {
        width: 33%;
    }
    section#features .feature > * {
        vertical-align: top;
    }
    
    section#features .feature i {
        width: 60px;
        font-size: 40px;
        opacity: .3;
    }
    section#features .feature .description {
        width: calc(100% - 60px);
        padding-right: calc(var(--padding-large) * 2);
        text-align: justify;
    }
    section#features .feature .description label {
        font-size: var(--font-size-medium);
        text-transform: uppercase;
        font-weight: bold;
    }
    section#features .feature .description p {
        font-size: var(--font-size-base);
        color: rgba(64,64,64,.7);
        opacity: .9;
        line-height: 24px;
    }
    section#features .actions {
        width: 100%;
        text-align: center;
        margin-top: calc(var(--margin-large) * 3);
    }
    section#features .actions a {
        margin: auto;
        position: relative;
        display: block;
        width: fit-content;
    }

    section#dashboard img.dashboard {
        position: relative;
        display: block;
        width: 90%;
        margin: 0 auto;
    }

    footer {
        background: #242424;
        color: #fff;
        position: relative;
        display: block;
        bottom: 0;
        width: 110vw;
        left: -5vw;
        text-align: center;
        margin-bottom: calc(var(--padding-large) * -2);
        color: rgba(64,64,64,.7);
        padding: 100px 0;
    }

    footer * {
        color: #fff;

    }
</style>

<style scoped>
/* Responsive */
@media only screen and (max-width: 1200px) {
    section#features,
    section#tutorial {
        padding: var(--padding-medium);
    }
}

@media only screen and (max-width: 700px) {    
    section {
        padding: 5vh 0;
    }

    .wrapper, footer {
        left: calc(var(--padding-medium) * -1);
        width: 100vw;
    }

    h1 {
        font-size: var(--font-size-large);
        font-weight: 700;
        margin-top: 5vh;
        margin-bottom: 30px;
        letter-spacing: 0px;
    }

    .cover p, .cover b {
        width: 100%;
        font-size: 16px;
        line-height: initial;
    }

    .cover p:last-of-type {
        margin-bottom: 5vh;
    }

    .cover .actions, .cover .actions button {
        width: 100%;
        margin: var(--margin-base) auto;
        margin-top: calc(var(--margin-large) * 2);
    }
    
    section h2 {
        font-size: var(--font-size-large);
    }

    #tutorial .step .description h4 {
        font-size: var(--font-size-medium) !important;
    }
    #tutorial .step .description p {
        font-size: var(--font-size-small) !important;
        line-height: initial !important;
    }
    #tutorial .step > * {
        margin: 0;
        width: 100% !important;
        left: initial !important;
        font-size: var(--font-size-base);
    }
    #tutorial .step > img {
        right: initial;
        width: 120% !important;
        margin: 0 auto;
        display: block;
        position: relative;
        left: -10% !important;
    }

    
    section#features .feature {
        width: 100%;
        margin-bottom: var(--margin-large);
    }
    
    section#features .feature i {
        width: 60px;
        font-size: 30px;
        opacity: .3;
    }
    section#features .feature .description {
        padding: 0;
    }
    section#features .feature .description label {
        font-size: var(--font-size-base);
    }
    section#features .feature .description p {
        font-size: var(--font-size-small);
    }
    section#features .actions {
        margin: var(--margin-large) 0;
    }
}
</style>
