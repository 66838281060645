var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"landing"},[_c('section',{staticClass:"cover"},[_c('h1',{staticClass:"animated fadeIn"},[_vm._v("You need a schema registry.")]),_vm._m(0),_c('p',{staticClass:"animated fadeIn"},[_vm._v("No infrastructure to manage, SaaS-easy.")]),_c('div',{staticClass:"actions"},[_c('router-link',{staticClass:"back",attrs:{"to":{ name: 'signup' }}},[_c('button',{staticClass:"animated fadeIn"},[_vm._v("Sign up for beta")])])],1)]),_c('div',{staticClass:"wrapper"},[_c('section',{attrs:{"id":"tutorial"}},[_c('h2',{staticClass:"animated fadeIn"},[_vm._v("How SchemaDB works")]),_c('div',{staticClass:"step"},[_vm._m(1),_c('img',{staticClass:"inline-block",attrs:{"src":_vm.startImg}})]),_c('div',{staticClass:"step"},[_vm._m(2),_c('img',{staticClass:"inline-block",attrs:{"src":_vm.encodeImg}})]),_c('div',{staticClass:"step"},[_vm._m(3),_c('img',{staticClass:"inline-block",attrs:{"src":_vm.decodeImg}})])])]),_c('section',{attrs:{"id":"dashboard"}},[_c('h2',[_vm._v("User-friendly Dashboard")]),_c('img',{staticClass:"dashboard",attrs:{"src":_vm.uiImg}})]),_c('div',{staticClass:"wrapper"},[_c('section',{attrs:{"id":"features"}},[_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"actions"},[_c('router-link',{staticClass:"back",attrs:{"to":{ name: 'signup' }}},[_c('button',[_vm._v("Sign up for beta")])])],1)])]),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"animated fadeIn"},[_vm._v("Are you building an event-driven platform? Do your services exchange messages? "),_c('b',[_vm._v("Optimize")]),_vm._v(" your payloads and discover how "),_c('b',[_vm._v("easy")]),_vm._v(" schema governance can be!")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description inline-block"},[_c('h4',[_vm._v("1. Save a new schema version")]),_c('p',[_vm._v("Import SchemaDB SDK and initialize it with your API Token. Read our "),_c('a',{attrs:{"href":"https://schemadb.github.io/node-sdk","target":"_blank"}},[_vm._v("developer docs")]),_vm._v(" for detailed instructions.")]),_c('p',[_vm._v("Declare your Avro schema following "),_c('a',{attrs:{"href":"https://avro.apache.org/docs/current/spec.html#json_encoding","target":"_blank"}},[_vm._v("the specification")]),_vm._v(".")]),_c('p',[_vm._v("Use the SDK to save the version to SchemaDB. After that, your schema will appear on your "),_c('a',{attrs:{"href":"/dashboard","target":"_blank"}},[_vm._v("Dashboard")]),_vm._v(" and will be available to serialize and parse your messages.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description inline-block"},[_c('h4',[_vm._v("2. Encode your payload")]),_c('p',[_vm._v("Fetch the lastest schema version by its "),_c('b',[_vm._v("namespace")]),_vm._v(" and "),_c('b',[_vm._v("name")]),_vm._v(" using the SDK. You can also get an speficic version if you prefer, Read our "),_c('a',{attrs:{"href":"https://schemadb.github.io/node-sdk","target":"_blank"}},[_vm._v("developer docs")]),_vm._v(" for more details.")]),_c('p',[_vm._v("Once you have the schema, simply use the SDK to serialize your message. The SDK handles validation and caching for you.")]),_c('p',[_vm._v("After that you cand send your message out to a Kafka cluster or any other streaming platform.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description inline-block"},[_c('h4',[_vm._v("3. Decode Avro messages")]),_c('p',[_vm._v("When you encode a message using our SDK we include the schema reference, so we can decode it anytime.")]),_c('p',[_vm._v("This "),_c('b',[_vm._v("decode")]),_vm._v(" method gets the schema used for serialization for you, and uses it for parsing. After that, you get the original payload as a JSON object.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature inline-block"},[_c('i',{staticClass:"icon material-icons-outlined inline-block"},[_vm._v("done_all")]),_c('div',{staticClass:"description inline-block"},[_c('label',[_vm._v("Easy")]),_c('p',[_vm._v("Set it all up in a few minutes. Use our SDK al let it to all the heavy-lifting for you.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature inline-block"},[_c('i',{staticClass:"icon material-icons-outlined inline-block"},[_vm._v("speed")]),_c('div',{staticClass:"description inline-block"},[_c('label',[_vm._v("Fast")]),_c('p',[_vm._v("Our SDK bundles a blazing fast serializer and deserializar. It also handles caching for you.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature inline-block"},[_c('i',{staticClass:"icon material-icons-outlined inline-block"},[_vm._v("trending_up")]),_c('div',{staticClass:"description inline-block"},[_c('label',[_vm._v("scalable")]),_c('p',[_vm._v("It doesn't matter if you have 2 or 2 million schemas, SchemaDB grows at the pace you need.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('label',[_vm._v("SchemaDB 👋 ")]),_c('a',{attrs:{"href":"mailto:hi@schemadb.com"}},[_vm._v("hi@schemadb.com")])])
}]

export { render, staticRenderFns }